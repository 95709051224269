<template>
  <div class="py-md-8 py-6">
    <v-form v-model="formIsValid" @submit.prevent="handleFormSubmit" ref="sqftForm" lazy-validation>

      <v-row>
        <v-col>
          <label class="text-subtitle-2 gray--text text--darken-2 d-inline-block mb-3">
            {{ $t("createOrder.kitchenLayout.form.knowsSqft.label") }}
          </label>
          <v-radio-group hide-details="auto" class="knows-sqft-options mb-5" row v-model="form.knowsSqft">
            <v-radio active-class="active" class="image-radio" :value="true"
                     :label="$t('defaults.binaryQuestionAnswers.yes')" />
            <v-radio active-class="active" class="image-radio" :value="false"
                     :label="$t('defaults.binaryQuestionAnswers.no')" />
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row v-if="form.knowsSqft !== null">
        <v-col lg="5" md="8" cols="12">
          <label class="d-inline-block text-subtitle-2 gray--text text--darken-2 mb-2">
            {{ $t("createOrder.kitchenLayout.form.sqft.label") }}
          </label>
          <v-currency-field
            single-line
            v-model="form.sqft"
            :rules="rules.sqft"
            class="rounded-0 sqft-input"
            :disabled="form.knowsSqft === false"
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <div v-if="form.knowsSqft === false" class="countertop-sqft-calculator d-md-flex px-md-5 py-md-5 px-4 py-4 border-all mt-4">
        <div class="countertop-sqft-calculator-image">
          <img :src="getAttachmentPreviewURL(kitchenLayoutType.countertopSquareFootageCalculatorImage)" :alt="kitchenLayoutType.displayName" />
        </div>
        <div class="ms-md-5 mt-md-0 mt-4">
          <label class="text-subtitle-2 font-weight-regular gray--text text--darken-3 mb-4">
            {{ $t("createOrder.kitchenLayout.calculator.title") }}
          </label>
          <div class="countertop-sqft-calculator-inputs">
            <v-currency-field
              v-for="side in layoutSides"
              :key="side.text"
              class="rounded-0 mb-md-4 me-md-5"
              hide-details="auto"
              v-model="form.sides[side.model]"
              :label="side.text"
              :rules="rules.sides[side.model]"
              outlined
            />
          </div>
        </div>
      </div>

      <div class="mt-md-8 mt-6 action-buttons">
        <v-btn
          type="button"
          elevation="0"
          class="mr-4 rounded-0"
          outlined
          @click="goToPreviousStep"
          large
        >
          {{ $t("defaults.back") }}
        </v-btn>
        <v-btn
          type="submit"
          elevation="0"
          color="primary"
          :disabled="!formIsFilled || !formIsValid"
          class="rounded-0"
          large
        >
          {{ $t("defaults.continue") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getAttachmentPreviewURL } from "@/utils/attachment";
import { mapActions, mapState } from "vuex";

const sides = [
  { text: "Side A", model: "a" },
  { text: "Side B", model: "b" },
  { text: "Side C", model: "c" },
  { text: "Side D", model: "d" },
  { text: "Side E", model: "e" },
  { text: "Side F", model: "f" },
  { text: "Side G", model: "g" },
  { text: "Side H", model: "h" }
];

export default {
  data() {
    return {
      formIsValid: false,
      form: {
        knowsSqft: null,
        sqft: null,
        sides: {
          a: null,
          b: null,
          c: null,
          d: null,
          e: null,
          f: null,
          g: null,
          h: null
        }
      },
      rules: {
        sqft: [
          v => v !== null || this.$t("createOrder.kitchenLayout.form.sqft.rules.required"),
          v => (v >= 10 && v <= 300) || this.$t("createOrder.kitchenLayout.form.sqft.rules.range")
        ],
        sides: {
          a: [v => v !== null || "Enter side A"],
          b: [v => v !== null || "Enter side B"],
          c: [v => v !== null || "Enter side C"],
          d: [v => v !== null || "Enter side D"],
          e: [v => v !== null || "Enter side E"],
          f: [v => v !== null || "Enter side F"],
          g: [v => v !== null || "Enter side G"],
          h: [v => v !== null || "Enter side H"]
        }
      }
    };
  },
  watch: {
    ["form.sides"]: {
      handler() {
        this.calculateCountertopSqft();
      },
      deep: true
    },
    ["form.knowsSqft"](_, oldValue) {
      if (oldValue !== null) {
        this.resetLocalSqftFields();
        this.$refs.sqftForm.resetValidation();
      }
    }
  },
  computed: {
    ...mapState("KitchenLayout", {
      kitchenLayoutType: state => state.selectedKitchenLayoutType,
      storedSqft: state => state.sqft,
      storedKnowsSqft: state => state.knowsSqft,
      storedSides: state => state.sides
    }),
    layoutSides() {
      const numberOfSides = this.kitchenLayoutType.numberOfSidesToCalculateCountertopSquareFootage;
      return sides.slice(0, numberOfSides);
    },
    formIsFilled() {
      if (this.form.knowsSqft === null) {
        return false;
      }

      if (this.form.knowsSqft === true) {
        return this.form.sqft !== null;
      }

      return this.layoutSides.every(side => this.form.sides[side.model] !== null);
    }
  },
  methods: {
    getAttachmentPreviewURL,
    ...mapActions("Steps", ["completeStep"]),
    ...mapActions("KitchenLayout", ["saveSqft", "saveKnowsSqft", "saveSides"]),
    calculateCountertopSqft() {
      // eslint-disable-next-line
      const { a, b, c, d, e, f, g, h } = this.form.sides;
      const countertopSqft = eval(this.kitchenLayoutType.countertopSquareFootageCalculationFormula);
      this.form.sqft = countertopSqft.toFixed(2);
    },
    goToPreviousStep() {
      this.$router.push("/design-countertop/kitchen-layout/kitchen-shape");
    },
    handleFormSubmit() {
      const isValid = this.$refs.sqftForm.validate();
      if (isValid) {
        this.saveSqft(this.form.sqft);
        this.saveKnowsSqft(this.form.knowsSqft);
        this.saveSides(this.form.sides);
        this.completeStep("kitchenLayout/sqft");
        this.$router.push("/design-countertop/colors/floor");
      }
    },
    resetLocalSqftFields() {
      this.form.sqft = null;
      this.form.sides = {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
        g: null,
        h: null
      };
    }
  },
  mounted() {
    if (this.storedSqft) {
      this.form.sqft = this.storedSqft;
    }
    if (this.storedKnowsSqft !== null) {
      this.form.knowsSqft = this.storedKnowsSqft;
    }

    Object.keys(this.storedSides).forEach((side) => {
      if (this.storedSides[side] !== null) {
        this.form.sides[side] = this.storedSides[side];
      }
    });
  }
};
</script>
